// extracted by mini-css-extract-plugin
export var bronzeSponsor = "sponsors-module--bronzeSponsor--d2c92";
export var goldSponsor = "sponsors-module--goldSponsor--435c4";
export var h2 = "sponsors-module--h2--78017";
export var headingLine = "sponsors-module--headingLine--8c9f3";
export var mainSponsor = "sponsors-module--mainSponsor--4412a";
export var mainWrapper = "sponsors-module--mainWrapper--58655";
export var otherSponsors = "sponsors-module--otherSponsors--f154d";
export var silverSponsor = "sponsors-module--silverSponsor--db26a";
export var sponsorTypeHeading = "sponsors-module--sponsorTypeHeading--ddaf0";
export var sponsorsSection = "sponsors-module--sponsorsSection--6dd9f";
export var sponsorsType = "sponsors-module--sponsorsType--028ee";